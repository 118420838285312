import serverCheck from "../../modules/serverCheck";
import toast from "../../modules/toast";
import BaseService from "../BaseService";

/**
 * Service for fetching enumerations from the customers backend service.
 */
class CustomersEnumsService extends BaseService {
  baseUrl = `${serverCheck.getServer().serverCustomer}`;

  cacheOptions = {
    prefix: "customers-enums",
    ttl: 30 // 30 minutes
  };

  /**
   * @returns {Promise<CountryAPIModel[]>}
   */
  async countries() {
    try {
      const res = await this.httpClient.get("country");
      return res.data.data;
    } catch (error) {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText: error.response?.data?.errorMessage ?? error.message
      });
      throw error;
    }
  }

  /**
   * Get a list of enumerations based on type. The parameters are optional and
   * provided based on the type of enumeration.
   *
   * @template {CustomerEnumTypes} T
   * @param {T} type
   * @param {EnumsParamsMap[T]} params
   * @returns {Promise<EnumsTypeMap[T][]>}
   */
  async list(type, params) {
    try {
      const res = await this.httpClient.get(`enums/${type}`, {
        params
      });
      return res.data.data;
    } catch (error) {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText: `There was an error fetching ${type}. Please try again.`
      });
      throw error;
    }
  }
}

const customersEnumsService = new CustomersEnumsService();

export default customersEnumsService;

/**
 * @typedef {object} CountryAPIModel
 * @prop {number} countryId
 * @prop {string} name
 * @prop {string} code
 */

/**
 * @typedef {object} StateAPIModel
 * @prop {number} stateId
 * @prop {string} stateName
 * @prop {string} stateShortName
 */

/**
 * @typedef {object} BCGLCodeAPIModel
 * @prop {number} bcGlCodeId
 * @prop {string} bcGlCode
 * @prop {string} description
 * @prop {string} bcGlCodeType
 * @prop {0 | 1} activeInd
 */

/**
 * @typedef {object} PaymentTypeAPIModel
 * @prop {number} paymentTypeId
 * @prop {string} description
 * @prop {0 | 1 | null} activeInd
 */

/**
 * @typedef {Object} CurrencyAPIModel
 * @prop {number} currencyId
 * @prop {string} name
 * @prop {string} symbol
 * @prop {string} isoCurrencyCode
 */

/**
 * @typedef {'states' |
 * 'bcGlCodes' |
 * 'paymentTypes'|
 * 'currencies'
 * } CustomerEnumTypes
 */

/**
 * @typedef {Object} EnumsTypeMap Map of the API Response based on enum type
 * @prop {StateAPIModel} states
 * @prop {BCGLCodeAPIModel} bcGlCodes
 * @prop {PaymentTypeAPIModel} paymentTypes
 * @prop {CurrencyAPIModel} currencies
 */

/**
 * @typedef {Object} BCGLCodeParams
 * @prop {"CUSTOMER" | "SUPPLIER"} [glCodeType]
 */

/**
 * @typedef {object} EnumsParamsMap Map of the parameters based on enum type
 * @prop {BCGLCodeParams} bcGlCodes
 */
